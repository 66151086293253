/* eslint-disable */
import React, {useEffect, useRef, useState} from "react";
import {Validation} from "./formValidations";
import useUpdateEffect from "../hooks/useUpdateEffect";
import useMask from "../hooks/useMask";
// @ts-ignore

interface FormInputProps {
  value: string,
  onInput: (value: string) => void,
  label: string,
  validations: Validation[],
  name: string,
  mask?: string,
  onInvalid: (arg1: boolean, arg2: string) => void,
  placeholder: string,
}

export default function FormInput({ value, onInput, label, validations, name, mask, onInvalid, placeholder }: FormInputProps) {
  const [inputLabel, setInputLabel] = useState<string>(label);

  const inputRef = useRef(null);

  function touchInput() {
    const error = validations.find(({ validator }) => !validator(value));
    setInputLabel(error ? error.errorMessage : label)
  }

  useUpdateEffect(touchInput, [value]);

  useEffect(() => {
    const hasError =!validations.every(({ validator }) => validator(value));
    onInvalid(hasError, name);
  }, [value]);

  if (mask) {
    useMask(mask, inputRef);
  }
  return (
    <div className={`form-input ${inputLabel !== label ? '_error' : ''}`}>
      <input
        ref={inputRef}
        type="text"
        className={`form-input__el ${value.length ? '_filled' : ''}`}
        value={value}
        onInput={(e) => onInput((e.target as HTMLInputElement).value)}
        name={name}
        onBlur={touchInput}
        placeholder={placeholder}
      />
      <span
        className="form-input__label"
        dangerouslySetInnerHTML={{ __html: inputLabel}}
      />
    </div>
  )
}
