import React, { useRef } from "react";
// interface IFilterItemProps {
//   children: any;
//   caption: string;
//   active: boolean;
//   onSubmit: () => void;
// }

// @ts-ignore
export default function FilterItem({ children, caption, onSubmit, btnDisabled, btnReset }: any) {
  const formRef = useRef(null);
  return (
    <form className="form-item" ref={formRef}>
      { caption }
      <div className="form-column">
        { children }
        <div className="btn-search-wrapper">
          <button
            type="button"
            className={`btn btn-search${ btnReset ? ' btn-reset' : '' }`}
            onClick={ onSubmit }
            disabled={btnDisabled}
          >
            { btnReset ? 'Сбросить' : 'Применить' }
          </button>
        </div>
      </div>
    </form>
  )
}
