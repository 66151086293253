/* eslint-disable */
import React, {FC} from "react";

interface IInfoHintProps {
  index: number;
}

interface IHintProps {
  profession: string | null;
}

interface IHintWrapperProps {
  children: React.ReactElement;
}

const HintWrapper: FC<IHintWrapperProps> = ({children}) => (
  <div className="info-hint__text median-hint hint">
    {children}
  </div>
);

export const Hint: FC<IHintProps> = ({profession}) => (
  <HintWrapper>
    <p className="hint__item">
      {`Для крайних значений шкалы мы посчитали средние зарплаты в этой ${profession ? 'профессии' : 'сфере'} во всех регионах и выделили из них минимальную и максимальную.`}
    </p>
  </HintWrapper>
);

export const SalaryInfoHint: FC<IHintProps> = ({profession}) => (
  <HintWrapper>
    <>
      Для расчётов средней зарплаты
      мы&nbsp;взяли вакансии с&nbsp;самым распространённым графиком
      работы в&nbsp;этом месяце.
      <a href={`/about#${profession ? 'profession' : 'industry'}`} className="info-hint__link">
        Подробнее о расчётах
      </a>
    </>
  </HintWrapper>
);

export const InfoHint: FC<IInfoHintProps> = ({index}) => (
  <div className="info-hint__text">
    Отношение соискателей к&nbsp;активным вакансиям.
    Для удобства сравнения между регионами и&nbsp;профессиями мы&nbsp;оценили его по&nbsp;шкале
    от&nbsp;1&nbsp;до&nbsp;10.
    <a href="/about#table" className="info-hint__link">
      Подробнее об индексе
    </a>
  </div>
);
