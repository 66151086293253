import React from "react";

export default function PersonIcon() {
  return (
    <svg width="27" height="29" viewBox="0 0 27 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.0051 15.3889C19.4796 13.9496 21.1296 9.96624 19.6903 6.4917C18.2511 3.01717 14.2677 1.36723 10.7932 2.80646C7.31862 4.24569 5.66868 8.22908 7.10791 11.7036C8.54714 15.1782 12.5305 16.8281 16.0051 15.3889Z" stroke="white" strokeWidth="1.46855" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M25.7349 28.2238C25.7349 21.4144 20.2022 15.8817 13.3928 15.8817C6.58342 15.8817 1.05078 21.4144 1.05078 28.2238" stroke="white" strokeWidth="1.46855" strokeMiterlimit="10" strokeLinecap="round"/>
    </svg>
  )
}
