import Inputmask from 'inputmask';

export default function useMask(mask: string, elementRef: any) {
  if (!mask) return;

  if (elementRef.current) {
    const inputmask = new Inputmask(mask, { showMaskOnHover: false });
    inputmask.mask(elementRef.current);
  }
}
