import { styled } from "@mui/material/styles";
import { TextField } from "@mui/material";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import { autocompleteClasses } from "@mui/material/Autocomplete";

const StyledTextField = styled(TextField)({
  [`.${outlinedInputClasses.root} .${autocompleteClasses.input}`]: {
    padding: "15px 20px 17px",
    "@media (max-width: 1024px)": {
      padding: "10px 12px 11px",
    },
    "@media (max-width: 768px)": {
      padding: "5px 7px 6px",
    },
  },
  [`.${outlinedInputClasses.root}`]: {
    padding: 0,
    fontSize: "inherit",
    lineHeight: "inherit",
  },
  [`.${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
    border: "none",
  },
  [`.${outlinedInputClasses.input}`]: {
    height: "fit-content",
  },
});

export default StyledTextField;
