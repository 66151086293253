interface UseAnimationProps {
  isActive: boolean,
  element: HTMLElement,
}

const ANIMATION_DURATION = 200;

export default function useAnimation({ element, isActive }: UseAnimationProps) {
  if (!element) return;
  if (isActive) {
    /* eslint-disable */
    if (element.style.display === 'block') return;
    element.style.display = 'block';
    element.animate([
      { opacity: '0' },
      { opacity: '1' },
    ], ANIMATION_DURATION);
  } else {
    if (element.style.display === 'none') return;
    element.animate([
      { opacity: '1' },
      { opacity: '0' },
    ], ANIMATION_DURATION).finished.then(() => {
      element.style.display = 'none';
    })
  }
}
