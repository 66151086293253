import React from "react";

export default function QuestionIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM6.85712 5.78836C6.7402 6.32813 7.08298 6.86048 7.62275 6.97739C8.16252 7.09431 8.69487 6.75152 8.81179 6.21176C8.95797 5.53686 9.56006 5.0318 10.2778 5.0318C11.1062 5.0318 11.7778 5.70337 11.7778 6.5318C11.7778 7.00584 11.5631 7.4164 11.1854 7.688C10.8266 7.94601 10.5639 8.07912 10.1988 8.26415L10.0608 8.33422C9.38577 8.67819 9 9.36831 9 10.0834V11.0001C9 11.5523 9.44771 12.0001 10 12.0001C10.5523 12.0001 11 11.5523 11 11.0001V10.1003L11.1023 10.0485C11.4703 9.8626 11.8629 9.66422 12.353 9.31182C13.2483 8.66808 13.7778 7.65574 13.7778 6.5318C13.7778 4.5988 12.2108 3.0318 10.2778 3.0318C8.59902 3.0318 7.19841 4.21271 6.85712 5.78836ZM10 17C10.8284 17 11.5 16.3284 11.5 15.5C11.5 14.6716 10.8284 14 10 14C9.17157 14 8.5 14.6716 8.5 15.5C8.5 16.3284 9.17157 17 10 17Z"
        fill="#D9D9D9"
      />
    </svg>
  )
}
