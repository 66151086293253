/* eslint-disable */
import React, { useEffect } from "react";
import Table from "./table";
import Accordion from "./accordion";
import AboutTabs from "./aboutTabs";
import { scroller } from "react-scroll";

export default function AboutPage() {
  useEffect(() => {
    const urlId = (new URL(window.location.href)).hash.replace('#', '');
    try {
      scroller.scrollTo(urlId, {
        smooth: true,
        duration: 1200,
        delay: 500,
        isDynamic: true,
      });
    }
    catch (e) {
      alert(e);
    }

  });
    // @ts-ignore
  return (
      <>
        <section className="hero">
          <div className="container">
            <div className="hero__wrapper">
              <h1 className="hero__title">
                Открытая
                аналитика для&nbsp;оценки конкуренции в&nbsp;найме персонала
              </h1>
              <img src="abt-hero-decor.svg" alt="" className="hero__decor visible-mobile" />
              <img src="abt-hero-decor-desc.svg" alt="" className="hero__decor hidden-mobile" />
            </div>
          </div>
        </section>
        <section className="about">
          <div className="container">
            <div className="about__intro">
                <p className="about__description">
                  Рынок труда сильно меняется,
                  поэтому компаниям приходится быстро
                  подбирать новые стратегии найма.
                </p>
                <h2 className="about__title">
                  <div className="about__title-container">
                    Наша аналитика поможет вам найти
                    <hr/>
                  </div>
                  <div className="about__title-container">
                    аргументы для принятия решений.
                    <hr/>
                  </div>
                </h2>
            </div>
            <h3 className="about__subtitle">
              Что покажет инструмент
            </h3>
            <div className="about__tabs">
              <AboutTabs />
              <button className="btn btn-call tabs__btn">
                <a href="/">
                  Оценить конкуренцию
                </a>
              </button>
            </div>
          </div>
        </section>
        <section className="gauge">
          <div className="about__intro">
            <h2 className="about__subtitle">
              Что такое индекс рынка труда
              от&nbsp;Авито Работы
            </h2>
            <p className="about__description">
              Это отношение соискателей к&nbsp;активным вакансиям.
              Если простыми словами, это показатель конкуренции на&nbsp;рынке труда,
              которую мы&nbsp;оценили по&nbsp;шкале <b>от&nbsp;1&nbsp;до&nbsp;10.</b>
            </p>
            <div className="container">
              <button className="btn btn-call visible-mobile" id="table">
                <a href="index-table.pdf" target="_blank">
                  Таблица PDF
                </a>
              </button>
            </div>
          </div>
          <div className="gauge__table hidden-mobile" id="table">
            <Table />
          </div>
        </section>
        <section className="how-to">
          <div className="container">
            <h3 className="how-to__title" id="industry">
              Как читать шкалу зарплат
              для сферы деятельности
            </h3>
            <div className="how-to__item">
              <img src="abt-card.png" alt="" className="how-to__decor"/>
              <div className="how-to__text _bottom">
                <div className="how-to__text-item">
                  Для крайних значений шкалы мы&nbsp;посчитали средние зарплаты в&nbsp;этой сфере во&nbsp;всех регионах и&nbsp;выделили из&nbsp;них максимальную
                  и&nbsp;минимальную. Стрелка показывает уровень средней предлагаемой зарплаты
                  в&nbsp;выбранной сфере и&nbsp;регионе по&nbsp;наиболее популярному графику работы.
                </div>
                <div className="how-to__text-item">
                  Если стрелка находится в&nbsp;крайней левой
                  или в&nbsp;крайней правой точке, значит,
                  в&nbsp;выбранном регионе минимальная
                  или максимальная средняя зарплата
                  в&nbsp;этой сфере.
                </div>
              </div>
            </div>
            <h3 className="how-to__title" id="profession">
              Как читать шкалу зарплат
              для профессии
            </h3>
            <div className="how-to__item">
              <div className="how-to__text _top">
                <div className="how-to__text-item">
                  Для крайних значений мы&nbsp;посчитали средние зарплаты в&nbsp;этой профессии во&nbsp;всех регионах и&nbsp;выделили из&nbsp;них максимальную и&nbsp;минимальную. Стрелка показывает уровень средней зарплаты в&nbsp;выбранной профессии и&nbsp;регионе по&nbsp;наиболее популярному графику работы.
                </div>
                <div className="how-to__text-item">
                  Если стрелка находится в&nbsp;крайней левой или в&nbsp;крайней правой точке, значит, в&nbsp;выбранном регионе минимальная или максимальная
                  из&nbsp;всех средних зарплат
                  в&nbsp;этой профессии.
                </div>
              </div>
              <img src="abt-card-2.png" alt="" className="how-to__decor"/>
            </div>
          </div>
        </section>
        <section className="more">
            <h3 className="more__title">
              Ответы на частые вопросы
            </h3>
          <div className="more__accordions container">
            <Accordion title="Какую информацию показывает инструмент">
              <ul className="more__list">
                {[
                  'Уровень конкуренции и её динамику на рынке труда.',
                  'Количество вакансий в регионах.',
                  'Средние зарплаты в конкретном округе, регионе и городе.',
                  'Динамику зарплат за последний месяц и год.'
                ].map((item) => (
                  <li
                    className="more__list-item"
                    key={item}
                  >
                    { item }
                  </li>
                ))}
              </ul>
            </Accordion>
            {/* @ts-ignore */}
            <Accordion title="Как пользоваться фильтрами">
              Всего пять фильтров: по&nbsp;федеральным округам, регионам, городам, сферам и&nbsp;профессиям.<br />
              Профессию и&nbsp;сферу выбрать одновременно не&nbsp;получится, только что-то одно из&nbsp;них.<br /> Если указать сферу, то&nbsp;метрики будут учитывать все профессии, которые в&nbsp;неё входят.<br /> Одна и&nbsp;та&nbsp;же профессия может быть в&nbsp;разных сферах. Например, вакансии водителей можно встретить в&nbsp;разделах &laquo;Такси&raquo; и&nbsp;&laquo;Производство, сырьё, с/х&raquo;.
            </Accordion>
            <Accordion title="За какой период считаются данные">
              Чтобы показатели были достоверными и&nbsp;актуальными, мы&nbsp;берём данные за&nbsp;предыдущий завершённый месяц. Информация обновляется 1-го числа нового месяца. Например, 1&nbsp;июля индекс покажет результаты за&nbsp;июнь.
            </Accordion>
            <Accordion title="Почему зарплата считается только по одному из графиков работы">
              Для достоверности среднего значения мы&nbsp;учитывали тот график работы, где больше всего вакансий. Например, для отрасли продаж&nbsp;&mdash; это сменный, а&nbsp;для охраны и&nbsp;строительства&nbsp;&mdash; вахта.
            </Accordion>
          </div>
        </section>
      </>
    )
}
