import React, {useState} from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation} from "swiper";
/* eslint-disable */
import RubleIcon from "./svg/rubleIcon";
import CoefficientIcon from "./svg/coefficientIcon";
import {imgUrl, mapMonths} from "../utils";
import PersonIcon from "./svg/personIcon";
/* eslint-enable */

const date = new Date();

const currentMonth = date.toLocaleString('ru', {month: 'long'});
const currentYear = date.getFullYear() - 1;

date.setMonth(date.getMonth() + 1);

// @ts-ignore
const nextMonth = mapMonths[date.toLocaleString('ru', {month: 'long'})];
const nextYear = date.getFullYear();

const tabList: string[] = [
  'Менять ли бюджет <br>на подбор сотрудников',
  'В каких регионах и сферах лучше <br>разместить вакансии',
  'Конкурентно ли ваше предложение',
  'Что делать, чтобы оптимизировать <br>подбор сотрудников'
];

function Slider() {
  return (
    <Swiper
      slidesPerView={1}
      modules={[Navigation]}
      navigation
    >
      <SwiperSlide>
        <div className="tabs__slider-img flex">
          <div className="tabs__content">
            <div className="tabs__content-title">
              Рассчитаем бюджет на&nbsp;подбор сотрудников в&nbsp;текущем периоде
            </div>
            <div className="tabs__content-row">
              <div className="tabs__content-img c-purple">
                <RubleIcon/>
              </div>
              <div className="tabs__content-text">
                Бюджет на подбор<br/>
                за <b>{currentMonth} {currentYear}</b><br/>
                <i>Количество <br/>принятых на работу</i>
              </div>
            </div>
            <div className="tabs__content-row">
              <div className="tabs__content-img c-blue">
                <CoefficientIcon/>
              </div>
              <div className="tabs__content-text">
                Коэффициент изменения конкуренции на&nbsp;рынке труда за&nbsp;год
              </div>
            </div>
            <div className="tabs__content-row">
              <div className="tabs__content-img c-green">
                <PersonIcon/>
              </div>
              <div className="tabs__content-text">
                Принятый кандидат в {nextMonth} {nextYear}
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div
          className="tabs__slider-img"
          style={{
            backgroundImage: imgUrl('slide-2-img.svg'),
            backgroundPosition: 'center'
          }}
        />
      </SwiperSlide>
      <SwiperSlide>
        <div className="tabs__slider-img flex">
          <div className="tabs__slider-img-title">
            Чтобы вычислить коэффициент:
            <ol>
              {[
                '1.  Переведите показатель прироста или падения конкуренции из процентов в десятичную дробь.',
                '2.  Если конкуренция выросла, к получившемуся числу прибавьте 1.',
                '3.  Если конкуренция упала, вычтите из 1 получившееся число.',
              ].map((item) => <li key={item}>{item}</li>)}
            </ol>
          </div>
          <div className="tabs__content" style={{backgroundImage: imgUrl('slide-3-img-desc.svg')}}/>
        </div>
      </SwiperSlide>
    </Swiper>
  )
}

export default function AboutTabs() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [tabActive, setTabActive] = useState(tabList[activeIndex]);

  function changeTab(tab: string, index: number) {
    setTabActive(tab);
    setActiveIndex(index);
  }

  return (
    <div className="tabs__wrapper">
      <div className="tabs__items hidden-tablet">
        {tabList.map((tab: string, index: number) => (
          <div
            role="button"
            tabIndex={-1}
            className={`tabs__item${tabActive === tab ? ' active' : ''}`}
            key={tab}
            onClick={() => changeTab(tab, index)}
            onKeyDown={() => changeTab(tab, index)}
            dangerouslySetInnerHTML={{__html: tab}}
          />
        ))}
      </div>
      <div className="tabs__slider hidden-mobile hidden-tablet">
        {activeIndex === 0 ?
          <Slider/> :
          <div className="tabs__slider-img" style={{backgroundImage: imgUrl(`tab-item-${activeIndex}.svg`)}}/>
        }
      </div>
      <div className="tabs__slider visible-mobile tabs__slider-container">
        <Slider/>
        <p className="tabs__slider-subtitle visible-tablet">
          Менять ли бюджет на подбор сотрудников
        </p>
      </div>
      <div className="tabs__slider visible-tablet tab">
        <div className="tabs__slider-img flex">
          <div className="tabs__content">
            <div className="tabs__content-title">
              Рассчитаем бюджет на&nbsp;подбор сотрудников в&nbsp;текущем периоде
            </div>
            <div className="tabs__content-row">
              <div className="tabs__content-img c-purple">
                <RubleIcon/>
              </div>
              <div className="tabs__content-text">
                Бюджет на подбор<br/>
                за <b>{currentMonth} {currentYear}</b><br/>
                <i>Количество <br/>принятых на работу</i>
              </div>
            </div>
            <div className="tabs__content-row">
              <div className="tabs__content-img c-blue">
                <CoefficientIcon/>
              </div>
              <div className="tabs__content-text">
                Коэффициент изменения конкуренции на&nbsp;рынке труда за&nbsp;год
              </div>
            </div>
            <div className="tabs__content-row">
              <div className="tabs__content-img c-green">
                <PersonIcon/>
              </div>
              <div className="tabs__content-text">
                Принятый кандидат в {nextMonth} {nextYear}
              </div>
            </div>
          </div>
        </div>
        <div
          className="tabs__slider-img"
          style={{backgroundImage: imgUrl('slide-2-img.svg')}}
        />
        <div
          className="tabs__slider-img"
          style={{backgroundImage: imgUrl('slide-3-img.svg')}}
        />
      </div>
      <p className="tabs__slider-subtitle visible-tablet hidden-mobile">
        Менять ли бюджет на подбор сотрудников
      </p>
      <div className="tabs__list visible-tablet tab">
        {[1, 2, 3].map((number) => (
          <div className="tabs__slider-container">
            <div
              key={number}
              className="tabs__slider-img"
              style={{
                backgroundImage: imgUrl(`tab-item-${number}.svg`),
                backgroundPositionY: number === 3 ? '130%' : ''
              }}
            />
            <p
              className="tabs__slider-subtitle visible-tablet"
              dangerouslySetInnerHTML={{__html: tabList[number]}}
            />
          </div>
        ))}
      </div>
    </div>
  )
}
