import React from "react";

export default function CoefficientIcon() {
  return (
    <svg width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.4311 20.8712C15.0257 18.3532 13.4665 15.83 10.9485 15.2355C8.4306 14.6409 5.90743 16.2001 5.31287 18.7181C4.71831 21.236 6.27752 23.7592 8.79546 24.3538C11.3134 24.9483 13.8366 23.3891 14.4311 20.8712Z" stroke="white" strokeWidth="1.46855" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M17.8203 29.9362C16.5986 26.7458 13.499 24.4741 9.87447 24.4741C5.17968 24.4741 1.36328 28.2804 1.36328 32.9853" stroke="white" strokeWidth="1.46855" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M1.43359 33.0458H20.7478V11.6114H14.2862V13.9436" stroke="white" strokeWidth="1.46855" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M27.2097 0.757935H20.748V33.0459H27.2097V0.757935Z" stroke="white" strokeWidth="1.46855" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M33.6706 5.78595H27.209V33.046H33.6706V5.78595Z" stroke="white" strokeWidth="1.46855" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}
