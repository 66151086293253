/* eslint-disable */
import React from "react";
import { Autocomplete } from "@mui/material";
import StyledTextField from "./styledTextField";
import SelectArrow from "./svg/selectArrow";

export interface IFilterFieldProps {
  label: string;
  value: string | null;
  itemList: any,
  placeholder: string;
  onListChange: (arg1: any) => void;
  onOpen: () => void;
  onClose: (arg1: any) => void;
  additionalData?: any;
  disabled: boolean;
}

export default function FilterField(
  { label, itemList, placeholder, onListChange, value, onClose, onOpen, additionalData, disabled }: IFilterFieldProps
) {
  return (
    <label className="filter">
      { label }
      <Autocomplete
        popupIcon={ <SelectArrow /> }
        disabled={ disabled }
        noOptionsText="Нет результатов"
        options={ Object.values(itemList).sort() }
        renderInput={(params) => (
          <StyledTextField
            { ...params }
            placeholder={ placeholder }
            className="search"
          />
        )}
        onChange={(e, newValue) => onListChange(newValue)}
        value={ value }
        onClose={ onClose }
        onOpen={ onOpen }
        { ...(additionalData || {})}
      />
    </label>
  )
}
