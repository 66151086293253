/* eslint-disable */
import {
    ThemeProvider,
    createTheme, Switch,
} from "@mui/material";

import { YMaps, withYMaps } from "@pbe/react-yandex-maps";
import { YMapsApi } from "@pbe/react-yandex-maps/typings/util/typing";
import React, {useEffect, useMemo, useState} from "react";
// @ts-ignore
import {createBrowserRouter, createRoutesFromChildren, Outlet, Route, RouterProvider, Routes, useLocation} from "react-router-dom";
import { KeepAlive, Provider } from "react-keep-alive";
import ReactDOM from "react-dom/client";
import Header from "./components/header";
import "./index.scss";
import Footer from "./components/footer";
import Index from "./components/indexPage";
import AboutPage from "./components/aboutPage";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <>
        <Header isIndex={ window.location.pathname === '/' } />
        {window.location.pathname === '/' ? <Index /> : window.location.pathname === '/about' ? <AboutPage /> : null}
        <Footer />
    </>
);
