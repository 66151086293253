/* eslint-disable */

import { Tooltip } from "@mui/material";
import {Map, withYMaps, YMaps} from "@pbe/react-yandex-maps";
import {YMapsApi} from "@pbe/react-yandex-maps/typings/util/typing";
import React, {FC, useEffect, useMemo, useRef, useState} from "react";
import ProgressBar from "./progressBar";
import {
  DEFAULT_COLOR,
  FilterResponce,
  SearchResponse,
  IDistrictCollection,
  IRegionCollection,
  STROKE_COLOR,
  StringObject,
  allDistricts,
  districtByRegion,
  scrollToInfo,
  monthNames,
  mapMonths,
  regionOnMapByApiRegion,
  districtMap,
} from '../utils';
import IndexTooltip from "./indexTooltip";
import QuestionIcon from "./svg/questionIcon";
import {SalaryInfoHint, InfoHint, Hint} from "./hints";
import FilterItem from "./filterItem";
import FilterField, { IFilterFieldProps } from "./filterField";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCards } from "swiper";
import Loader from "./loader";
import Modal, {ModalTypes} from "./modal";
import CallForm from "./callForm";

const COLORS = {
  'Ниже среднего': '#FF4053',
  'Соответствует среднему': '#00AAFF',
  'Выше среднего': '#04E061',
  None: '#04E061',
};

interface ButtonProps {
  children: React.ReactNode;
  className?: string;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  onClick?: () => void;
}

const Button: FC<ButtonProps> = ({children, className, type, disabled, onClick}) => (
  <button className={`btn ${className}`} type={type} disabled={disabled} onClick={onClick}>
    {children}
  </button>
);

interface InfoContentProps {
  index: number;
  MOM: string;
  YOY: string;
  prof: string;
  place: string;
}

const InfoContent: FC<InfoContentProps> = ({index, MOM, YOY, prof, place}) => {
  const makeText = (period: string, value: string) => {
    if (value == "Нет данных за более ранний период") {
      return value;
    }

    return `За последний ${period} конкуренция
    за соискателя ${value}`;
  };

  return (
    <div className="info-content">
      <div className="info-hint">
        {/* @ts-ignore */}
        <Tooltip title={null} PopperComponent={InfoHint} PopperProps={{index}}>
          <QuestionIcon/>
        </Tooltip>
      </div>
      <div className="info-index">
        <div className="info-text">
          {prof} в локации <span>&laquo;{place}&raquo;</span>
        </div>
        <ProgressBar value={index} min={1} max={10} hasTooltip={true} hasCaption={true}>
          <IndexTooltip index={index}/>
        </ProgressBar>
      </div>
      <div className="info-changes">{makeText("месяц", MOM)}</div>
      <div className="info-changes">{makeText("год", YOY)}</div>
    </div>
  );
};

function IndexPage({ymaps}: { ymaps: YMapsApi }) {
  const [mapState] = useState<ymaps.IMapState>({
    behaviors: [], // запрещаем все пользовательские действия (зум, перетаскивание)
    // center: document.body.clientWidth < 1024 ? [68, 110] : [65, 180],
    bounds: [
      [41.185996, 19.484764],
      [81.886117, 191.128012],
    ],
    zoom: 2,
    // @ts-ignore, неправильно описан тип поля type -> null убирает фон карты, undefined не убирает фон карты
    type: null, // убираем подложку карты
    controls: [], // убираем все инструменты управления (зум, поиск)
  });

  // Значения фильтров
  const [district, setDistrict] = useState<string | null>("Центральный федеральный округ");
  const [region, setRegion] = useState<string | null>(null);
  const [city, setCity] = useState<string | null>(null);
  const [profession, setProfession] = useState<string | null>(null);

  // Объекты на карте
  const [districtCollection, setDistrictCollection] = useState<IDistrictCollection>({});
  const [regionCollection, setRegionCollection] = useState<IRegionCollection>({});
  const [cityIcon, setCityIcon] = useState<any>();
  const [range, setRange] = useState('None');

  // Списки для автокомплита
  const [districtList, setDistrictList] = useState<StringObject>({});
  const [regionList, setRegionList] = useState<StringObject>({});
  const [cityList, setCityList] = useState<StringObject>({});
  const [professionList, setProfessionList] = useState<StringObject>({});

  // Данные от API
  const [index, setIndex] = useState(1);
  const [schedule, setSchedule] = useState("");
  const [medianSalary, setMedianSalary] = useState("");
  const [maxSalary, setMaxSalary] = useState(220000);
  const [minSalary, setMinSalary] = useState(15000);
  const [salaryMOM, setSalaryMOM] = useState("");
  const [salaryYOY, setSalaryYOY] = useState("");
  const [MOM, setMOM] = useState("");
  const [YOY, setYOY] = useState("");
  const [infoDate, setInfoDate] = useState("");
  const [infoYear, setInfoYear] = useState(new Date().getFullYear());
  const [prof, setProf] = useState("");
  const [place, setPlace] = useState("");
  const [currentDistrict, setCurrentDistrict] = useState('Все федеральные округа');

  const [wasSearched, setWasSearched] = useState(false);
  const [isLoadingFilter, setIsLoadingFilter] = useState(false);
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);
  const [activeFormSlide, setActiveFormSlide] = useState(0);

  const [modalType, setModalType] = useState<ModalTypes>(null);

  const mapRef = useRef<ymaps.Map | undefined>(undefined);

  const [isInitial, setIsInitial] = useState(true);

  interface IFiltersData {
    district: string | null;
    region: string | null;
    city: string | null;
    profession: string | null;
  }

  const fetchFiltersData = async ({
                                    district,
                                    region,
                                    city,
                                    profession,
                                  }: IFiltersData) => {
    const params = {} as Record<string, string>;
    if (district) params.federal_district = district;
    if (region) params.region = region;
    if (city) params.city = city;
    if (profession) params.profession = profession;

    const query = Object.keys(params)
      .map((q) => encodeURIComponent(q) + "=" + encodeURIComponent(params[q]))
      .join("&");

    const url =
      `${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_FILTER_URL}?` + query;

    // console.log("Поисковый запрос по фильтрам: ", url);

    const options = {
      method: "GET",
      headers: {
        Authorization: "Basic bm92YXRlc3Q6eG5vdmF0ZXN0",
      },
    };
    const response = await fetch(url, options);
    const json: FilterResponce = await response.json();
    // console.log("Результат поиска по фильтрам: ", json, url);

    delete json.region["Any"];
    delete json.city["Any"];
    delete json.profession["Any"];

    setDistrictList(json.federal_district);
    setRegionList(json.region);
    setCityList(json.city);
    setProfessionList(json.profession);
  };

  const handleSearch = async (
    district: string | null,
    region: string | null,
    city: string | null,
    profession: string | null,
  ) => {
    if ((!district && !region && !city) || (!profession)) {
      return;
    }

    setIsLoadingSearch(true);
    setWasSearched(true);

    let queryMap = "";
    let paramsApi = {} as Record<string, string>;

    if (district) {
      queryMap += `${(district === 'Все федеральные округа' || district === 'Российская Федерация') ? 'Россия' : district} `;
      paramsApi.federal_district = `${district === 'Российская Федерация' ? 'Все федеральные округа' : district} `;
    }
    // else {
    //   paramsApi.federal_district = "Российская Федерация";
    // }

    if (region) {
      queryMap += `${region} `;
      paramsApi.region = region;
    } else if (!city) {
      paramsApi.region = "Any";
    }

    if (city) {
      queryMap += `город ${city}`;
      paramsApi.city = city;
    } else {
      paramsApi.city = "Any";
    }

    paramsApi.industry = "Any";

    if (profession) {
      paramsApi.profession = profession;
    } else {
      paramsApi.profession = "Any";
    }

    // Запрос данных
    const queryApi = Object.keys(paramsApi)
      .map((q) => encodeURIComponent(q) + "=" + encodeURIComponent(paramsApi[q]))
      .join("&");

    const url =
      `${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_SEARCH_URL}?` +
      queryApi;

    // console.log("Поисковый запрос по данным: ", url);

    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic bm92YXRlc3Q6eG5vdmF0ZXN0",
      },
    };
    const response = await fetch(url, options);
    const json: SearchResponse = (await response.json())[0];
    // console.log("Результат поиска по данным: ", json);
    const jsonDate = new Date(json.event_month.split(' ')[0]);
    setInfoDate(monthNames[jsonDate.getMonth()]);
    setInfoYear(jsonDate.getFullYear());
    setIndex(parseFloat(json.index));
    setSchedule(json.schedule);
    setMedianSalary(json.median_salary);
    setSalaryMOM(json.salary_mom);
    setSalaryYOY(json.salary_yoy);
    setCurrentDistrict(json.federal_district);
    // @ts-ignore
    setRange(json.item_range);
    setMaxSalary(+json.max_salary);
    setMinSalary(+json.min_salary);
    setMOM(json.mom);
    setYOY(json.yoy);
    setProf(
      json.profession !== "Any"
        ? json.profession[0].toLocaleUpperCase() + json.profession.slice(1)
        : "Any"
    );
    setPlace(
      json.city !== "Any"
        ? json.city
        : json.region !== "Any"
          ? json.region
          : json.federal_district
    );

    // Поиск по карте
    // console.log(`Поисковый запрос по карте: ${queryMap}`);
    ymaps
      .geocode(queryMap, {
        results: 1,
      })
      .then((result) => {
        // Выбираем первый (и единственный) результат поиска
        const firstGeoObject = result.geoObjects.get(0);
        // console.log("Результат поиска по карте:", firstGeoObject);
        if (!firstGeoObject) return;

        // @ts-ignore
        const coords = firstGeoObject.geometry.getCoordinates(); // данная функция не описана даже в документации
        const bounds = firstGeoObject.properties.get("boundedBy", {}) as number[][];

        if (!mapRef.current) return;
        // Масштабируем карту на область видимости геообъекта.
        mapRef.current.setBounds(bounds);

        // Стабилизируем масштабирование
        let offsetBasis = 5;
        let zoom = mapRef.current.getZoom();

        if (zoom >= 6) {
          zoom = 5;
          offsetBasis = 4;
        } else if (zoom < 2) {
          zoom = 1.5;
        }
        // console.log(zoom);
        mapRef.current.setZoom(zoom);

        // Сдвигаем карту влево, если поверх карты есть блок с информацией
        if (matchMedia('(min-width: 1024px)').matches) {
          // Получаем центр карты и смещаем его на часть от ширины контейнера карты
          let pixelCenter = mapRef.current.getGlobalPixelCenter();
          const mapContainer = mapRef.current.container;
          const offset = Math.min(mapContainer.getSize()[0] / offsetBasis, 270);
          // console.log('offset', offset);
          pixelCenter = [pixelCenter[0] + offset, pixelCenter[1]];
          mapRef.current.setGlobalPixelCenter(pixelCenter);
        }

        // Устанавлиаем цвета всех ФО в цвет по умолчанию
        Object.values(districtCollection).forEach((district) => {
          // @ts-ignore, функция set() не описана в типе options
          district.options.set({fillColor: DEFAULT_COLOR});
        });
        // Сбрасываем цвета всех регионов (надо именно сбрасывать, чтобы работала настройка цвета ФО)
        Object.values(regionCollection).forEach((region) => {
          region.options.unset("fillColor");
        });
        // Удаляем метку города
        mapRef.current.geoObjects.remove(cityIcon);

        // Выбираем ФО по его имени в коллекции геообъектов ФО на карте
        const highlightDistrict = district && districtCollection[district];
        // Выбираем регион по его имени в коллекции геообъектов регионов на карте
        const highlightRegion =
          region && regionCollection[regionOnMapByApiRegion[region]];

        // Красим либо регион (если он есть), либо целиком весь ФО
        // console.log(district, 'district');
        if (highlightRegion) {
          // @ts-ignore
          highlightRegion.options.set({fillColor: COLORS[json.item_range]});
        } else if (highlightDistrict) {
          // @ts-ignore, функция set() не описана в типе options
          highlightDistrict.options.set({fillColor: COLORS[json.item_range]});
        } else if (!highlightDistrict && district === "Все федеральные округа") {
          // Закрашиваем все ФО, если выбран ФО Российская Федерация
          Object.values(districtCollection).forEach((district) => {
            // @ts-ignore, функция set() не описана в типе options
            district.options.set({fillColor: COLORS[json.item_range]});
          });
        }

        // Добавляем метку для города
        if (city) {
          // @ts-ignore, функция set() не описана в типе options
          firstGeoObject.options.set("preset", "islands#darkBlueDotIconWithCaption");
          // Получаем строку с адресом и выводим в иконке геообъекта.
          // @ts-ignore, функция set() не описана в типе
          firstGeoObject.properties.set(
            "iconCaption",
            // @ts-ignore, тип объекта, возвращаемого в ответе геокодера шире обычного IGeoObject
            firstGeoObject.getAddressLine()
          );
          // Добавляем метку на карту
          mapRef.current.geoObjects.add(firstGeoObject);
          // Сохраняем геообъект метки в состояние для возможности последующего удаления
          setCityIcon(firstGeoObject);
        }
      })
      .catch(console.log);

    setIsLoadingSearch(false);
  };

  useEffect(() => {
    if (!isInitial || !Object.keys(districtCollection).length) return;
    setIsInitial(false)
    handleSearch('Центральный федеральный округ', null, null, 'Курьер')
  }, [districtCollection, isInitial]);



  const handleClick = () => {
    if (wasSearched) {
      setWasSearched(false);
      setDistrict('Все федеральные округа');
      setRegion(null);
      setCity(null);
      setProfession(null);
      setActiveFormSlide(0);
    } else {
      handleSearch(district, region, city, profession);
      scrollToInfo();
    }
  };

  const getRegions = (ymaps: YMapsApi) => {
    if (mapRef.current) {
      // @ts-ignore, модуль borders не описан
      ymaps.borders
        .load("RU", {
          lang: "ru",
          quality: 2,
        })
        // тип result это FeatureCollection, см. https://yandex.ru/dev/maps/jsapi/doc/2.1/dg/concepts/regions.html#regions__geojson-obj
        .then((result: any) => {
          const regions: IRegionCollection = {};
          const districts: IDistrictCollection = {};

          for (const districtName of Object.values(allDistricts)) {
            districts[districtName] = new ymaps.GeoObjectCollection(
              {},
              {
                fillColor: DEFAULT_COLOR,
                strokeColor: STROKE_COLOR,
                strokeWidth: 1,
                hasHint: false,
                cursor: "none",
              }
            );
            // Свойство, хранящее наименование ФО
            districts[districtName].properties.name = districtName;
          }

          result.features.forEach((feature: any) => {
            const regionIso: string = feature.properties.iso3166;
            const regionName = feature.properties.name;
            const districtName = districtByRegion[regionIso];

            // Формируем геообъект региона
            const regionGeoObj = new ymaps.GeoObject(feature);
            // Сохраняем регион (ссылку) в общую коллекцию регионов
            regions[regionName] = regionGeoObj;
            // Добавим регион в соответствующую ФО коллекцию для последующего отображения на карте
            districts[districtName].add(regionGeoObj);
          });

          if (mapRef.current) {
            for (const districtName of Object.keys(districts)) {
              // Добавим коллекцию на карту
              mapRef.current?.geoObjects.add(districts[districtName]);
            }

            // Добавляем фон.
            const pane = new ymaps.pane.StaticPane(mapRef.current, {
              zIndex: 100,
              // @ts-ignore
              css: {
                width: "100%",
                height: "100%",
                backgroundColor: "#f7f7f7",
              },
            });
            mapRef.current.panes.append("background", pane);
          }

          setRegionCollection(regions);
          setDistrictCollection(districts);
          // console.log("Регионы на карте", regions);
        })
        .catch((e: any) => console.log(e));
    }
  };

  useEffect(() => {
    setWasSearched(false);
    setIsLoadingFilter(true);
    fetchFiltersData({district, region, city, profession})
      .catch(console.error)
      .finally(() => setIsLoadingFilter(false));
  }, [district, region, city, profession]);

  useEffect(() => {
    if (Object.keys(districtCollection).length) {
      handleSearch(district, null, null, null);
      setWasSearched(false);
    }
  }, [districtCollection]);

  const valueMap = {
    district, region, city, profession,
  }

  function createInputOnClose(variableName: any) {
    return ({target}: { target: any }) => {
      const value = target.value || target.textContent;
      const data = {...valueMap, [variableName]: value};
      // @ts-ignore
      fetchFiltersData(data);
    }
  }

  function createInputOnOpen(variableName: any) {
    return () => {
      // @ts-ignore
      if (valueMap[variableName]) {
        const data = {...valueMap, [variableName]: null};
        // @ts-ignore
        fetchFiltersData(data);
      }
    }
  }

  const professionFormData: IFilterFieldProps[] = [
    {
      label: 'Профессия',
      value: profession,
      itemList: professionList,
      disabled: activeFormSlide === 1,
      placeholder: 'Курьер',
      onListChange: setProfession,
      onOpen: () => {
        createInputOnOpen('profession')();
      },
      onClose: createInputOnClose('profession'),
      additionalData: {
        getOptionLabel: (option: any) => option[0].toLocaleUpperCase() + option.slice(1)
      }
    }
  ];

  const districtFormData: IFilterFieldProps[] = [
    {
      label: 'Федеральный округ',
      value: district,
      itemList: districtList,
      disabled: activeFormSlide === 0,
      placeholder: 'Центральный',
      onListChange: setDistrict,
      onOpen: createInputOnOpen('district'),
      onClose: createInputOnClose('district'),
      additionalData: {
        getOptionLabel: (option: string) => option.replace('федеральный округ', '')
      }
    },
    {
      label: 'Регион',
      value: region,
      disabled: activeFormSlide === 0,
      itemList: regionList,
      placeholder: 'Московская область',
      onListChange: setRegion,
      onOpen: createInputOnOpen('region'),
      onClose: createInputOnClose('region'),
    },
    {
      label: 'Город',
      value: city,
      disabled: activeFormSlide === 0,
      itemList: cityList,
      placeholder: 'Москва',
      onListChange: setCity,
      onOpen: createInputOnOpen('city'),
      onClose: createInputOnClose('city'),
    }
  ]

  const [swiper, setSwiper] = useState<any>();

  useEffect(() => {
    // console.log(activeFormSlide);
    swiper?.slideTo(activeFormSlide);
  }, [activeFormSlide])

  // @ts-ignore
  return (
    <main>
      <section className="intro-section">
        <div className="container">
          <h1 className="intro-title">
            Индекс <span>рынка труда</span> <br/> от Авито Работы
          </h1>
          <p className="intro-text">
            Индекс показывает соотношение спроса со&nbsp;стороны соискателей к&nbsp;предложению со&nbsp;стороны работодателей.
          </p>
          <p className="intro-text">
            Кроме индекса, на&nbsp;платформе можно узнать средние зарплаты
            и&nbsp;количество вакансий в&nbsp;регионах&nbsp;&mdash; это поможет оценить состояние рынка и&nbsp;подобрать
            стратегию найма сотрудников.
          </p>
        </div>
      </section>
      <section className="analytics-section">
        <div className="analytics-form">
          <div className={`form-slider__container ${activeFormSlide ? 'left' : 'right'}`}>
            <Swiper
              shortSwipes={true}
              className="form-slider__wrapper"
              effect={ matchMedia('(max-width: 767px)').matches ? 'slide' : 'cards'}
              centeredSlides={true}
              modules={[EffectCards]}
              speed={1000}
              noSwiping={!profession}
              noSwipingClass="swiper-slide"
              slideToClickedSlide={true}
              wrapperClass="form-slider"
              onInit={(swiper) => setSwiper(swiper)}
              onSlideChange={(swiper) => setActiveFormSlide(swiper.activeIndex)}
              centeredSlidesBounds={true}
              cardsEffect={{
                perSlideRotate: 0,
                rotate: false,
                slideShadows: false,
                perSlideOffset: 80,
              }}
            >
              <SwiperSlide>
                <Loader loading={ isLoadingFilter } />
                <FilterItem
                  caption={<>Выберите нужную профессию</>}
                  onSubmit={() => setActiveFormSlide(1)}
                  btnDisabled={!profession}
                >
                  { professionFormData.map((item) => <FilterField {...item} key={item.label}/>) }
                </FilterItem>
              </SwiperSlide>
              <SwiperSlide>
                <FilterItem
                  caption={<>Укажите локацию — федеральный округ,<br/> регион или город.</>}
                  onSubmit={handleClick}
                  btnDisabled={!district && !region && !city}
                  btnReset={wasSearched}
                >
                  <Loader loading={ isLoadingFilter } />
                  { districtFormData.map((item) => <FilterField {...item} key={item.label}/>) }
                </FilterItem>
              </SwiperSlide>
            </Swiper>
          </div>
          <div className="form-hint">
            Если нужные параметры недоступны, значит, у&nbsp;нас пока недостаточно информации. Задайте другие параметры или попробуйте позже.
          </div>
        </div>
        <div className="map-container">
          <div className="map-wrapper">
            <Map
              instanceRef={mapRef}
              state={mapState}
              options={{
                suppressMapOpenBlock: true, // скрываем метку "Открыть в яндекс картах"
              }}
              onLoad={getRegions}
              className="map"
              width="100%"
              height="100%"
            />
          </div>
          <div className="info-container">
            <InfoContent index={index} MOM={MOM} YOY={YOY} prof={prof} place={place}/>
            <div className="info-legend" style={{display: range === 'None' ? 'none' : 'block'}}>
              <div className="info-legend__title">
                Количество вакансий
              </div>
              <div className="info-legend__subtitle">
                Относительно среднего {!city && !region ? 'по России' : `в ${districtMap[currentDistrict || 'None']}`}
              </div>
              <div className="info-legend__colors">
                {[
                  {color: 'red', text: 'ниже среднего'},
                  {color: 'blue', text: 'равно среднему'},
                  {color: 'green', text: 'выше среднего'}].map(({color, text}) => (
                  <div className="info-legend__color" key={color}>
                    <div className={`info-legend__color-item ${color}`}/>
                    {text}
                  </div>
                ))
                }
              </div>
            </div>
          </div>
        </div>

        <div className="map-container" style={{marginTop: '10px'}}>
          {schedule === "None" || schedule === ""
            ? <div className="median-no-data">
              Для оценки средней предлагаемой зарплаты в&nbsp;данном срезе недостаточно данных
            </div>
            : <div className="articles-container median-container"
                   style={{marginTop: '10px', color: 'white', gap: '14px'}}>
              <div className="median-item">
                <div className="info-hint">
                  {/* @ts-ignore */}
                  <Tooltip title={null} PopperComponent={SalaryInfoHint} PopperProps={{profession}}>
                    <QuestionIcon/>
                  </Tooltip>
                </div>
                <div className="median-item__progress">
                  <div>
                    <div className="median-item__salary">{(+medianSalary).toLocaleString('ru-RU')} ₽</div>
                    <div className="median-item__salary-caption">Средняя предлагаемая зарплата</div>
                  </div>
                  <div className="median-item__progress-wrapper">
                    <ProgressBar min={minSalary} max={maxSalary} value={+medianSalary} hasTooltip={true}>
                      <Hint profession={profession}/>
                    </ProgressBar>
                  </div>
                </div>
                <div className="median-item__description">
                  {/* @ts-ignore */}
                  В {mapMonths[infoDate]} наибольшее количество вакансий с&nbsp;графиком &laquo;{schedule}&raquo;.
                </div>
              </div>
              {
                <div style={{
                  background: !salaryMOM.includes('%') && !salaryYOY.includes('%') ? '#DFDFDF' : '#00AAFF',
                }} className="median-item">
                  <h2 className="median-item__title">
                    Динамика зарплаты
                  </h2>

                  {!salaryMOM.includes('%') && !salaryYOY.includes('%')
                    ? <div className="median-item__no-data">
                      Нет данных за&nbsp;более <br/>ранние периоды
                    </div>
                    :
                    <div className="median-item__inner">
                      <div className="median-item__col">
                        <div
                          className={`median-item__percentage${salaryMOM.includes('%') ? '' : '-sm'}`}
                          dangerouslySetInnerHTML={{__html: salaryMOM.trim().toLowerCase().replace(' ', '<br> ')}}
                        />
                        <div className="median-item__caption">
                          за месяц
                        </div>
                      </div>
                      <div className="median-item__col">
                        <div
                          className={`median-item__percentage${salaryYOY.includes('%') ? '' : '-sm'}`}
                          dangerouslySetInnerHTML={{__html: salaryYOY.trim().toLowerCase().replace(' ', '<br> ')}}
                        />
                        <div className="median-item__caption">
                          за год
                        </div>
                      </div>
                    </div>}
                </div>
              }
            </div>
          }
          <p className="info-date">По состоянию на {infoDate} {infoYear}</p>
        </div>
      </section>

      <section className="cta-section">
        <div className="container">
          <h3 className="cta-title">Поможем в массовом подборе</h3>
          <p className="cta-text">
            Если вы&nbsp;нанимаете от&nbsp;5&nbsp;человек в&nbsp;месяц, мы&nbsp;бесплатно предоставим вам
            персонального менеджера. Он&nbsp;проанализирует вашу статистику найма, подберёт
            инструменты продвижения и&nbsp;расскажет, как получать больше подходящих откликов.
          </p>
          <Button className="btn-call" onClick={() => setModalType('form')}>
            Заказать звонок
          </Button>
          <Button className="btn-place">
            <a href={process.env.REACT_APP_PLACE_VACANCY_URL} target="_blank">
              Разместить вакансию
            </a>
          </Button>
        </div>
      </section>
      <section className="articles-section">
        <div className="container">
          <h3 className="articles-title">
            Еще больше полезной <br/>
            информации и&nbsp;аналитики
          </h3>
          <div className="articles-container">
            <div className="article-card card-avito">
              <a href={process.env.REACT_APP_BLOG_URL} target="_blank">
                <div className="article-img">
                  <img src="avito.svg" alt="Avito icon" />
                </div>
                <p className="article-text">
                  Блог<br/> Авито
                  Работы
                </p>
              </a>
            </div>
            <div className="article-card card-telegram">
              <a href={process.env.REACT_APP_TELEGRAM_URL} target="_blank">
                <div className="article-img">
                  <img src="telegram.svg" alt="Telegram icon"/>
                </div>
                <p className="article-text">
                  Телеграм-канал <br/>
                  Авито Работы
                </p>
              </a>
            </div>
          </div>
          <p className="disclaimer">
            Аналитические данные рассчитываются на&nbsp;основании внутренней статистики Авито Работы, носят
            информационный характер, не&nbsp;являются исчерпывающими и&nbsp;предоставляются исключительно для целей
            ознакомления заинтересованными лицами с&nbsp;состоянием рынка труда на&nbsp;конкретную дату.<br /> Регионы на&nbsp;карте отображаются при наличии данных для анализа.
          </p>
        </div>
      </section>
      <Modal isActive={modalType === 'form'} onClose={() => setModalType(null)}>
        <CallForm
          onError={() => setModalType('error')}
          onSuccess={() => setModalType('success')}
        />
      </Modal>
      <Modal isActive={modalType === 'success'} onClose={() => setModalType(null)}>
        <h3 className="call-form__title">
          Успешно
        </h3>
        <p className="call-form__description">
          Менеджер свяжется с&nbsp;вами в&nbsp;течение суток по&nbsp;будням с&nbsp;10&nbsp;до&nbsp;18&nbsp;по&nbsp;московскому времени.
        </p>
      </Modal>
      <Modal isActive={modalType === 'error'} onClose={() => setModalType(null)}>
        <h3 className="call-form__title">
          Ошибка
        </h3>
        <p className="call-form__description">
          Попробуйте снова
        </p>
      </Modal>
    </main>
  );
}

export default function Index() {
  const Component = function ({ymaps}: { ymaps: YMapsApi }) {
    return (
      <IndexPage ymaps={ymaps}/>
    );
  }

  const ConnectedComponent = withYMaps(Component, true, []);

  return (
    <YMaps
      query={{
        ns: "use-load-option",
        apikey: "baa0eebb-8ccc-4cdf-9f0f-39928483493e",
        load: "package.full",
      }}
    >
      {/* @ts-ignore */}
      <ConnectedComponent/>
    </YMaps>
  );
}
